import SlimSelect from 'slim-select'
import LazyLoadImages from './modules/lazyLoadImages';
import LazyGoogleMaps from './modules/lazyGoogleMaps';
import FAQ from './modules/faq';
import Tabs from './modules/tabs';
import Animations from './modules/animations';
import searchBar from './modules/search';
import GLightbox  from 'glightbox';
import { tns } from "tiny-slider/src/tiny-slider";
import LiteYTEmbed  from 'lite-youtube-embed/src/lite-yt-embed';
import Navigation from './modules/navigation';


loadSliders(); //tns needs to attach before lazy loading is attached, otherwise it doesn't know what size the images are
LazyLoadImages(); //this should be first, or very nearly, the first thing to be loaded
LazyGoogleMaps();
FAQ();
Tabs();
Animations();
searchBar();
Navigation();

//triggering SlimSelect on all select & multiselect boxes
//search feature is available & turned on with the class 'select-search'
document.querySelectorAll('select').forEach(
    function (element) {
        new SlimSelect({
            select: element,
            showSearch: element.classList.contains('select-search'),
        })
    }
)

const lightbox = GLightbox(); 

function loadSliders() {
    document.querySelectorAll('.slider').forEach(sliderDiv => {
        tns({
            container: sliderDiv,
            autoplay: false,
            autoWidth: true,
            // fixedWidth: 300,
            // gutter: 20,
            lazyload: true,
            mouseDrag: true,
            autoplayButton: false,
            nav: false
        });
    });
}


/*new Glide('.glide', {
  type: 'carousel',
  startAt: 0,
  gap: 20
}).mount({ Controls, Keyboard });
*/
/****  change text form black to white based on bg colour   *****/


/*function getforeGColor(rgb) { 
            var cols = rgb.match(/^rgb\((\d+), \s*(\d+), \s*(\d+)\)$/); 
            var b = 1; 
            var r = cols[1]; 
            var g = cols[2]; 
            var b = cols[3]; 
            var yiq = ((r*299)+(g*587)+(b*114))/1000;
            return (yiq >= 180) ? 'black' : 'white';
        }


  if ( $( ".swatch" ).length ) {
        $('.swatch').each(function(){
                var bgcolor = $( this ).css( "background-color" );
                //console.log(bgcolor);
                var textColor = getforeGColor(bgcolor); 
               // console.log(textColor);

                $(this).css('color', textColor); 
                $(this).find('h4').css('color', textColor); 
                $(this).find('a').css('color', textColor); 

           });   
 
}*/
