export default function Navigation() {
    nav()    
}

//
// Define your functions here
//
function nav() {

  var navMenuTrigger = document.querySelector(".mobile-menu-trigger");
  var navMenu = document.querySelector(".nav-icon");
  var menuBar = document.querySelector(".menu-bar");

  if (typeof (navMenuTrigger) != 'undefined' && navMenuTrigger != null &&
      typeof (navMenu) != 'undefined' && navMenu != null) {
    	navMenuTrigger.onclick = function() {
			navMenu.classList.toggle('open');
			menuBar.classList.add('open');   
		}
	}

  var navMenuClose = document.querySelector(".header-close-icon");
  if (navMenuClose) {
    navMenuClose.addEventListener("click", function() {
        navMenu.classList.remove('open'); 
        menuBar.classList.remove('open');  
    });
  }

  var mobileMenuDropdown = document.querySelectorAll(".new-menu-icon");
  var i;

  if (mobileMenuDropdown) {
      for (i = 0; i < mobileMenuDropdown.length; i++) {
          mobileMenuDropdown[i].addEventListener("click", function() {
              var dropdown = this.parentElement.nextElementSibling;
              dropdown.classList.toggle('menu-open');
          });
      }
  }


  var menuBack = document.querySelectorAll(".mobile-menu-back-link");
  if (menuBack) {
      for (i = 0; i < menuBack.length; i++) {
          menuBack[i].addEventListener("click", function() {
              var mainNav = this.parentElement.parentElement;
              mainNav.classList.toggle('menu-open');
          });
      }
  }

}